<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <PageHeader text="Добавить Заболевания (состояния) в проект" />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Поиск"
        hide-details
        clearable
        class="mb-6"
      ></v-text-field>
      <NosologiesListWithSelect :nosologies="searchedNosologies" class="mb-4" />
    </div>
    <div v-else>Нет заболеваний (состояний) для отображения</div>
  </DisplayLoading>
</template>

<script>
import NosologiesListWithSelect from '@/components/project/NosologiesListWithSelect.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_NOSOLOGIES } from '@/store/const/apiPaths'

export default {
  data() {
    return {
      search: '',
      loadUrl: API_NOSOLOGIES,
      errorMessage: `Не удалось загрузить список заболеваний (состояний)`,
      loadingMessage: 'Загружаю список заболеваний (состояний)',
    }
  },
  components: {
    NosologiesListWithSelect,
    PageHeader,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    nosologies() {
      return this.pageData.map(n => ({
        ...n,
        searchString: [
          n.name,
          n.mkb10_codes,
          n.profiles.map(p => p.name).join(),
        ]
          .join(',')
          .toLowerCase(),
      }))
    },
    searchedNosologies() {
      if (!this.search) return this.nosologies
      const search = this.search.toLowerCase()
      return this.nosologies.filter(n => n.searchString.includes(search))
    },
  },
}
</script>

<style scoped>
.v-input >>> .v-label,
.v-input >>> .v-text-field__slot {
  padding-left: 24px;
}
</style>
