<template>
  <v-card class="mb-2 pa-1 pl-4 d-flex align-center">
    <div class="box">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        :size="16"
      ></v-progress-circular>
      <v-checkbox
        v-else
        readonly
        hide-details
        :disabled="nosologia.hasCriteria"
        :input-value="nosologia.selected"
        @click="onClick"
      ></v-checkbox>
    </div>
    <div>{{ nosologia.name }}</div>
    <div>{{ nosologia.profileName }}</div>
    <div>{{ nosologia.criteria }}</div>
  </v-card>
</template>

<script>
import { sendDataWithToastNoRedirect } from '@/mixins/sendDataWithToastNORedirect'
import { unpinNosologia, pinNosologiaWithGvs } from '@/lib/requestsAPI'

export default {
  data() {
    return {
      isLoading: false,
    }
  },
  props: ['nosologia', 'projectId'],
  mixins: [sendDataWithToastNoRedirect],
  methods: {
    onClick() {
      if (this.isLoading) return
      if (this.nosologia.hasCriteria) {
        this.$toast.warning(
          'Нельзя открепить заболевание (состояние) у которого уже есть критерии.\nСначала удалите критерии в настройках проекта.'
        )
        return
      }
      this.isLoading = true
      const nosology_id = this.nosologia.id
      const project_id = this.projectId
      if (this.nosologia.selected) {
        this.sendDataWithToastNoRedirect({
          action: unpinNosologia,
          onSuccess: this.onSuccess,
          onFinally: this.onFinally,
          data: { project_id, nosology_id },
          successMessage: 'Открепили от проекта',
          errorMessage: `Не удалось открепить заболевание (состояние) ${nosology_id} от проекта ${project_id}`,
        })
      } else {
        this.sendDataWithToastNoRedirect({
          action: pinNosologiaWithGvs,
          onSuccess: this.onSuccess,
          onFinally: this.onFinally,
          data: { project_id, nosology_id, stage_id: 1 },
          successMessage: 'Прикрепили заболевание (состояние) к проекту',
          errorMessage: `Не удалось прикрепить заболевание (состояние) ${nosology_id} к проекту ${project_id}`,
        })
      }
    },
    onSuccess() {
      this.$emit('update')
    },
    onFinally() {
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-input {
  margin: 0;
  padding: 0;
}

.v-input >>> .v-input--selection-controls__input {
  margin: 0;
  padding: 0;
}
</style>
