<template>
  <DisplayLoading
    :isLoading="isLoading"
    :isError="isError"
    :loadingMessage="loadingMessage"
    :errorMessage="errorMessage"
  >
    <div v-if="pageData">
      <NosologiaSelectCard
        v-for="nosologia in nosologiesWithSelect"
        :key="nosologia.id"
        :nosologia="nosologia"
        :projectId="projectId"
        @update="update"
      />
      <BackToProjectButton />
    </div>
    <div v-else>Нет проекта</div>
  </DisplayLoading>
</template>

<script>
import BackToProjectButton from '@/components/buttons/BackToProjectButton.vue'
import NosologiaSelectCard from '@/components/project/NosologiaSelectCard.vue'
import DisplayLoading from '@/components/common/DisplayLoading.vue'

import { routeParams } from '@/mixins/routeParams'
import { loadPageData } from '@/mixins/loadPageData'

import { API_PROJECTS } from '@/store/const/apiPaths'

export default {
  props: ['nosologies'],
  data() {
    return {
      loadingMessage: 'Загружаю список заболеваний (состояний) для проекта',
    }
  },
  components: {
    BackToProjectButton,
    NosologiaSelectCard,
    DisplayLoading,
  },
  mixins: [routeParams, loadPageData],
  computed: {
    loadUrl() {
      return `${API_PROJECTS}/${this.projectId}`
    },
    errorMessage() {
      return `Не удалось загрузить данные для проекта: ${this.projectId}`
    },
    project() {
      return this.pageData
    },
    nosologiesWithSelect() {
      const { projectNosologies } = this.project
      if (!projectNosologies) return []
      const selected = projectNosologies.map(p => p.nosology_id)
      const hasCriteria = projectNosologies
        .filter(p => p.criteria_count)
        .map(p => p.nosology_id)
      return this.nosologies.map(n => ({
        ...n,
        selected: selected.includes(n.id),
        hasCriteria: hasCriteria.includes(n.id),
      }))
    },
  },
}
</script>
